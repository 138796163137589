@import './variables.scss';

.Home {
  flex: 1;
  display: flex;
  align-items: stretch;
  background-color: $light;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: column;
    justify-content: space-around;
  }
  &:hover a {
    &:first-of-type {
      border-right: 1px solid transparent;
    }
  }
  a {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.5rem;
    font-weight: bold;
    &:hover {
      color: $dark !important;
      background-color: $medium;
      font-size: 1.55rem;
    }
    @media screen and (max-width: 992px) {
      flex: 1;
      font-size: 1.2rem !important;
    }
    @media screen and (max-width: 400px) {
      font-size: 1rem !important;
    }
    &:first-of-type {
      border-right: 1px solid white;
      @media (max-aspect-ratio: 1/1) {
        border-right: none !important;
        border-bottom: 1px solid white;
      }
    }
  }
}
