@import './variables.scss';

.Page {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  .container {
    h4:first-of-type {
      margin-top: 0;
    }
    p:first-of-type {
      margin-top: 0;
    }
    p:last-of-type {
      margin-bottom: 0;
    }
    margin: 1rem 0;
    padding: 3rem;
    border: 1px solid white;
    line-height: 1.6rem;
    max-width: 50vw;
    text-align: justify;
    li {
      margin: 1rem 0;
    }
    @media screen and (max-width: 992px) {
      border: none;
      margin: 0;
      padding: 1rem 2rem;
      max-width: 80vw;
      ul {
        padding-left: 1.8rem;
      }
    }
  }
}
