@import './variables.scss';

.Client {
  flex: 1;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }
  align-items: center;
  flex-direction: column;
  .client-content {
    padding: 2rem;
    border: 1px solid white;
    @media screen and (max-width: 992px) {
      background-color: $light;
    }

    .number {
      display: block;
      margin-top: -1.5rem;
      font-weight: bold;
      font-size: 7rem;
      text-decoration: none;
      @media screen and (max-width: 992px) {
        margin-top: -0.5rem;
        font-size: 2.7rem;
      }
    }
  }
}
