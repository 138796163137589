@import './variables.scss';

html {
  background-color: $light;
}

.App {
  color: $dark;
  display: flex;
  flex-direction: column-reverse;
  @media (max-aspect-ratio: 1/1) {
    flex-direction: column;
  }
  min-height: 100%;
  .danger {
    font-weight: bold;
    color: $light;
    background-color: $danger;
    padding: 0.3rem;
  }
  .success {
    font-weight: bold;
    color: $light;
    background-color: $dark;
    padding: 0.3rem;
  }
  a {
    color: inherit;
    transition: all 0.2s ease;
    &:hover {
      color: $highlight;
    }
  }
  header {
    a {
      text-decoration: none;
    }
    text-align: center;
    h1 {
      font-size: 2rem;
      margin-bottom: 0;
    }
    h2 {
      font-size: 0.9rem;
      margin-top: 0;
    }
  }
}
