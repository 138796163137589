@import './variables.scss';

.Providers {
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  @supports not (-ms-ime-align: auto) {
    justify-content: space-evenly;
  }
  form {
    padding: 2rem;
    border: 1px solid white;
    @media screen and (max-width: 992px) {
      border: none;
    }
    max-width: 90vw;
    h3 {
      margin-top: 0;
    }
    .checkbox {
      display: inline-block;
      &.conditions {
        margin-top: 1rem;
      }
      label {
        position: relative;
        margin-right: 1rem;
        font-size: 1rem;
        &::before {
          content: '';
          display: inline-block;
          height: 0.7rem;
          width: 0.7rem;
          border: 1px solid;
          margin-right: 0.2rem;
          background-color: white;
        }
        &::after {
          position: absolute;
          left: 0.1rem;
          top: 0.11rem;
          content: '';
          display: inline-block;
          height: 0.4rem;
          width: 0.6rem;
          border-left: 2px solid;
          border-bottom: 2px solid;
          transform: rotate(-45deg);
        }
      }
      input[type='checkbox'] {
        & + label::after {
          content: none;
        }
        &:checked + label::after {
          content: '';
        }
        &:focus + label::before {
          outline: $medium auto 5px;
        }
      }
    }
    label {
      display: block;
      &.small {
        font-size: 0.8rem;
      }
    }
    .checkgroup {
      margin: 0.1rem 0 1rem 0;
    }
    input {
      margin: 0.1rem 0 1rem 0;
      &[type='text'],
      &[type='tel'] {
        width: calc(100% - 1rem);
        height: 2rem;
        padding: 0.1rem 0.5rem;
        font-weight: bold;
        color: $dark;
        font-size: 0.9rem;
        border-color: $light;
      }
      &[type='checkbox'] {
        opacity: 0;
        position: absolute;
      }
      &[type='submit'] {
        float: right;
        margin-top: 1rem;
        margin-bottom: 0;
        padding: 1rem;
        font-family: inherit;
        font-weight: bold;
        background-color: $dark;
        color: $light;
        border: none;
        cursor: pointer;
        transition: background-color 0.2s ease;
        &:hover {
          background-color: $highlight;
        }
        &:focus {
          outline: 0;
        }
      }
    }
  }
  .links {
    width: 50%;
    display: flex;
    @supports not (-ms-ime-align: auto) {
      justify-content: space-evenly;
    }
    @media screen and (max-width: 992px) {
      width: 80%;
    }
    h4 {
      text-align: center;
      margin-left: 0.1rem;
      margin-right: 0.1rem;
    }
  }
}
