@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400&display=swap');

/* html,
body, */
#root {
  height: 100vh;
}

@media (max-aspect-ratio: 1/1) {
  #root {
    height: 88vh;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Raleway', sans-serif;
}
